import { LogoMainNSTGateway } from 'assests/Home';
import React from 'react';
import MainForm from './MainForm';

const SectionFormFill = () => {
    return (
        <section className="form-fill-section-main">
            <div className='container'>
                <div className="form-fill-main-container">
                    <div className="form-fill-content-left">
                        <div className="form-fill-heading">
                            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                        </div>
                        <div className="form-fill-content-below">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin semper elit vitae efficitur vestibulum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur non fringilla elit. Donec lobortis finibus sapien, ullamcorper hendrerit velit accumsan sed. Sed scelerisque est eu lorem maximus, vel cursus orci bibendum. Fusce at diam sit amet ligula vulputate fermentum. Proin placerat nulla eleifend eros iaculis fringilla. Donec et nibh eget risus ornare posuere.</p>
                        </div>
                    </div>
                    <div className="form-fill-out-main-form">
                        <div className="form-fill-main-wrapper">
                            <div className="nst-gateway-logo-main">
                                <LogoMainNSTGateway />
                            </div>
                            <div className="form-fill-main-heading-content">
                                <h5>Fill out this form and we will get back to you as soon as possible</h5>
                            </div>
                            <div className="form-fill-input-type">
                                <MainForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionFormFill;