import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import { ILoginReq } from "interfaces/authentication";
import { FormEvent, useState } from "react";

interface ILoginFormProps {
    formData: ILoginReq;
    formErrors: { [key: string]: string };
    loading: boolean;
    onHandleChange: (value: string, field: keyof ILoginReq) => void;
    onSubmit: (e: FormEvent) => void;
    onCancel: () => void;
}
const registerForm = {
    field: {
        email: {
            IField: 'email',
            Lable: 'Email',
            Placeholder: 'Email',
            IType: 'email',
        },
        pin: {
            IField: 'pin',
            Lable: 'Pin',
            Placeholder: 'PIN (Min 8 digits)',
            IType: 'password',
        },
    },
};
const LoginForm = ({
    formData,
    formErrors,
    loading,
    onHandleChange,
    onSubmit,
    onCancel
}: ILoginFormProps) => {
    const [showPin, setShowPin] = useState(false);
    const togglePinVisibility = () => setShowPin((prev) => !prev);

    const handleOnChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        isNumberOnly: boolean = false
    ) => {
        const { name, value } = e.target;
        const newValue = isNumberOnly ? value.replace(/\D/g, '') : value;
        onHandleChange(newValue, name as keyof ILoginReq);
    };
  return (
       <form onSubmit={onSubmit} noValidate autoComplete="off">
            <div className='form-group'>
                <TextField
                    fullWidth
                    placeholder={registerForm.field.email.Placeholder}
                    name={registerForm.field.email.IField}
                    value={formData.email}
                    onChange={handleOnChange}
                    inputProps={{ type: registerForm.field.email.IType }}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    autoComplete="off"
                />
            </div>
            <div className='form-group'>
                <TextField
                    fullWidth
                    name={registerForm.field.pin.IField}
                    placeholder={registerForm.field.pin.Placeholder}
                    type={showPin ? 'text' : 'password'}
                    value={formData.pin}
                    inputProps={{ inputMode: 'numeric' }}
                    onChange={(e) => handleOnChange(e, true)}
                    error={!!formErrors.pin}
                    helperText={formErrors.pin}
                    autoComplete="new-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePinVisibility}>
                                    {showPin ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className="nst_gateway_signupform_content_bottom">
                <div className="nst_gateway_signupform_handle_button">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress color="inherit" size={20} /> : null}
                        style={{ opacity: loading ? 0.8 : 1 }}
                    >
                        Login
                    </Button>
                    <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </form>
  )
}

export default LoginForm
