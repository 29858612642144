import BusinessForm from 'components/BusinessForm/BusinessForm';
import "./MerchantProcessingSolutions.css"
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RedeemIcon from '@mui/icons-material/Redeem';
import DiscountIcon from '@mui/icons-material/Discount';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';

const MerchantProcessingSolutionsPage = () => {
    return (
        <>
            <div className='nst-gate-page'>
                <div className="mps-top">
                    <div className="mps-content">
                        <h1>Merchant Processing Solutions</h1>
                        <p>Business Problems are Complicated. Payment Solutions Shouldn't Be. Our merchant processing services allow merchants to securely accept transactions wherever they occur.</p>
                    </div>
                </div>
                <div className="mps-bottom">
                    <div className="mps-form">
                        <BusinessForm title='Send a Message' />
                    </div>
                </div>
            </div>
            <div className="mps-solutions-container">
                <div className="mps-solutions-top">
                    <h1>
                        Solutions and services designed to help merchants grow.
                    </h1>
                </div>
                <div className="mps-solutions-parts-container">
                    <div className="mps-solutions-parts-left">
                        <div className="mps-solutions-parts-div">
                            <div className="mps-solutions-parts-icon"><CreditScoreIcon fontSize='large' /></div>
                            <div className="mps-solutions-parts-content">
                                <h3>Surcharging</h3>
                                <p>Offset the cost of accepting credit cards with an industry compliant surcharge program. We stay ahead of technology to make sure you are equipped with compatible, compliant POS payment processing software.</p>
                            </div>
                        </div>
                        <div className="mps-solutions-parts-div">
                            <div className="mps-solutions-parts-icon"><CurrencyExchangeIcon fontSize='large' /></div>
                            <div className="mps-solutions-parts-content">
                                <h3>Cash Discount</h3>
                                <p>NST Gateway is proud to offer an industry compliant cash discount program providing business owners a way to recoup much of the cost associated with accepting credit cards while offering a discount to cash, debit card & check payments.</p>
                            </div>
                        </div>
                        <div className="mps-solutions-parts-div">
                            <div className="mps-solutions-parts-icon"><RedeemIcon fontSize='large' /></div>
                            <div className="mps-solutions-parts-content">
                                <h3>Multi-Currency</h3>
                                <p>Multi-currency pricing allows you to price goods and charge customers in international currencies while receiving deposits in USD. With NST Gateway's MCP program you can capture additional customers by offering payment acceptance in 70+ currencies.</p>
                            </div>
                        </div>
                    </div>
                    <div className="mps-solutions-parts-right">
                        <div className="mps-solutions-parts-div">
                            <div className="mps-solutions-parts-icon"><DiscountIcon fontSize='large' /></div>
                            <div className="mps-solutions-parts-content">
                                <h3>Fraud Prevention</h3>
                                <p>NST Gateway offers protection from fraud and unwarranted chargebacks with a technology-driven strategy to fight chargebacks and recover lost revenue.</p>
                            </div>
                        </div>
                        <div className="mps-solutions-parts-div">
                            <div className="mps-solutions-parts-icon"><PhonelinkLockIcon fontSize='large' /></div>
                            <div className="mps-solutions-parts-content">
                                <h3>Gift and Loyalty Points</h3>
                                <p>Gift and Loyalty Programs are a great way to boost foot traffic and customer engagement. NST Gateway's gift and loyalty program is ideal for small to medium-sized businesses looking to increase revenue with an additional product and gain loyal or new customers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mps-info-container">
                <div className="mps-info-header">
                    <h1>Flexible and convenient payments on the go with our mobile payments solutions</h1>
                </div>
                <div className="mps-info-content">
                    <div className="mps-info-left-column">
                        <img src="NorthstarMedia/ContactUs/desk.png" alt="Placeholder" />
                    </div>
                    <div className="mps-info-right-column">
                        <div className="mps-info-column">
                            <h2>Simplicity and Access</h2>
                            <p>With on the go access to our credit card merchant services, you can quickly and easily check the status of your business no matter where you are. Easily measure and track your business with access to dashboard reporting, user management,technical support, and more.</p>
                        </div>
                        <div className="mps-info-column">
                            <h2>Security and Reliability</h2>
                            <p>Built with security in mind, our mobile solution supports swiped, keyed, and contactless transactions through practically any smartphone or tablet. All of our systems are EMV compliant to meet the highest global standards. We think about security and compliance so all you have to do is plug it in.</p>
                        </div>
                        <div className="mps-info-column">
                            <h2>Flexibility and Convenience</h2>
                            <p>Our flexible mobile payment solutions can be easily integrated with other point-of-sale systems creating an end-to-end solution for merchants. From delivery and personal services to contractors and repair shops, an mPOS solution offers flexibility and convenience.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mps-innovation-container">
                <div className="mps-innovation-header">
                    <h1>Innovative technology to easily integrate payments</h1>
                </div>
                <div className="mps-innovation-row">
                    <div className="mps-innovation-column">
                        <h2>Secure Data Exchange</h2>
                        <p>Our cutting-edge data exchange platform, VaultLink, provides secure and seamless transfer of sensitive information. Simplify data sharing, ensure compliance, and enhance collaboration across teams.</p>
                    </div>
                    <div className="mps-innovation-column">
                        <h2>Integrations</h2>
                        <p>NST Gateway offers integration solutions from a basic eCommerce configuration to an advanced feature-rich set-up with sophisticated fraud prevention and management tools.</p>
                    </div>
                    <div className="mps-innovation-column">
                        <h2>Tunnel Gateway</h2>
                        <p>Our proprietary gateway, Tunl, is designed to easily integrate payments and technology. Developers can leverage our out-of-the-box platform or integrate our APIs.</p>
                    </div>
                </div>
            </div>
            <div className='spacer'></div>
        </>
    );
}

export default MerchantProcessingSolutionsPage;