import BusinessForm from 'components/BusinessForm/BusinessForm';
import "./GetStarted.css";

const GetStartedPage = () => {
    return (
        <>
            <div className='nst-gate-page'>
                <div className="top">
                    <div className="content">
                        <h1>Fill out the form below and we will contact you shortly!</h1>
                        <p>Our flexible and secure payments platform and dedicated support makes it easy to accept payments anytime, anywhere.</p>
                    </div>
                </div>
                <div className="signup-bottom">
                    <div className="bottom">
                        <section className="signup_sec comm_auth_sec">
                            <BusinessForm
                                title={null}
                                requiredFields={['name', 'email', 'phone', 'comments']}
                                placeholders={{
                                    email: 'Mail',
                                    comments: "Message"
                                }} />
                        </section>
                    </div>
                </div>

            </div>
            <div className="contact-us">
                <div className="contact-top">
                    <span>Contact Us</span>
                </div>
                <div className="contact-details">
                    <div className="column">
                        <div className="address">
                            <h4>Address</h4>
                            <p>1968 S. Coast Hwy #2851</p>
                            <p>Laguna Beach, CA 92651</p>
                        </div>
                        <div className="phone" style={{ marginTop: '1rem' }}>
                            <h4>Phone Number</h4>
                            <p>Main Office: <a href="tel:+18006832977" style={{ textDecoration: 'none' }}><span>(800) 683-2977</span></a></p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="phone">
                            <h4>Email</h4>
                            <p>Customer Service</p>
                            <p><a href="mailto:support@northstarbct.com">Support@northstarbct.com</a></p>
                        </div>
                        <a href="mailto:support@northstarbct.com"><button className="submit-claim">Submit Claim</button></a>
                    </div>
                </div>
            </div>
            <div className="custom-div">
                <div className="custom-div-header">
                    <h2>Contact us directly</h2>
                    <p>Contact any one of our departments directly via email for answers to specific questions.</p>
                </div>
                <div className="custom-div-content">
                    <div className="custom-div-column">
                        <div className="custom-div-tab">
                            <h3>Customer Service</h3>
                            <a href="mailto:support@northstarbct.com">
                                <button className="custom-div-button">
                                    Email Now
                                </button>
                            </a>
                        </div>
                        <div className="custom-div-bordered">
                            <h3>Chargebacks and Risk</h3>
                            <a href="mailto:support@northstarbct.com">
                                <button className="custom-div-button">
                                    Email Now
                                </button>
                            </a>
                        </div>
                        <div className="custom-div-tab">
                            <h3>Underwriting</h3>
                            <a href="mailto:support@northstarbct.com">
                                <button className="custom-div-button">
                                    Email Now
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="custom-div-column">
                        <div className="custom-div-tab">
                            <h3>Technical Support</h3>
                            <a href="mailto:support@northstarbct.com">
                                <button className="custom-div-button">
                                    Email Now
                                </button>
                            </a>
                        </div>
                        <div className="custom-div-bordered">
                            <h3>Company Information</h3>
                            <a href="mailto:support@northstarbct.com">
                                <button className="custom-div-button">
                                    Email Now
                                </button>
                            </a>
                        </div>
                        <div className="custom-div-tab">
                            <h3>PCI Compliance</h3>
                            <a href="mailto:support@northstarbct.com">
                                <button className="custom-div-button">
                                    Email Now
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='spacer'></div>
        </>
    );
}

export default GetStartedPage;